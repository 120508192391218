
.contact-section {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    background-color: #f9f9f9; 
  }
  
  .contact-text {
    flex: 1;
    max-width: 40%; 
  }
  
  .contact-text h3 {
    font-family: 'Aeonik', sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #6C2BD9; 
  }
  
  .contact-text h2 {
    font-family: 'Aeonik', sans-serif;
    font-size: 32px;
    font-weight: 700;
    color: #000000; 
    margin: 10px 0;
  }
  
  .contact-text p {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    color: #333333; 
  }
  
  .contact-email {
    color: #6C2BD9; 
    text-decoration: none;
    font-weight: 500;
  }
  
  .contact-email:hover {
    text-decoration: underline;
  }
  
  
  .contact-form {
    flex: 1;
    max-width: 50%; 
    background-color: #f0e9ff; 
    padding: 40px;
    border-radius: 8px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 15px;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s ease;
    background-color: #fff;
  }
  
  input:focus,
  textarea:focus {
    border-color: #6C2BD9; 
  }
  
  textarea {
    height: 120px; 
    resize: none;
  }
  
  button {
    width: 100%;
    padding: 15px;
    background-color: #6C2BD9; 
    color: #fff;
    border: none;
    border-radius: 4px;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #5632a8; 
  }
  
  
  @media (max-width: 768px) {
    .contact-section {
      flex-direction: column;
      padding: 30px;
    }
  
    .contact-text,
    .contact-form {
      max-width: 100%;
    }
  
    .contact-text {
      margin-bottom: 30px;
    }
  }